export const getWineDetail = async (id) => {
  var urlencoded = new URLSearchParams()
  urlencoded.append("wine_id", id)
  const requestOptions = {
    method: "POST",
    headers: {
      "version-code": "1",
      "device-type": "iOS",
      "Content-Type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${process.env.GATSBY_PORTAL_TOKEN}`,
    },
    body: urlencoded,
    redirect: "follow",
  }

  try {
    const response = await fetch(
      `https://staging.iheartwine.com.au/api/get-wine-detail`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    return response
  } catch (error) {
    return error
  }
}
